<template>
  <div>
    <Title title="Audience" size="medium" class="mb-4" />

    <div class="relative w-full h-full audience">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-16" :style="`filter: ${!audienceData ? 'blur(1.5rem)' : 'none'}`">
        <div v-if="getCountries" class="mb-6">
          <PieChart title="Geographical distribution" :data-set="getCountries" class="max-w-full" />
        </div>

        <div v-if="getLocation" class="mb-6">
          <PieChart title="Worldwide cities" :data-set="getLocation" class="max-w-full" />
        </div>

        <div v-if="getLanguages" class="mb-6">
          <PieChart title="Browser languages" :data-set="getLanguages" class="max-w-full" />
        </div>
      </div>

      <div
        v-if="!audienceData"
        class="absolute top-1/2 left-1/2 w-full h-full text-center"
        style="transform: translate(-50%, -50%)"
      >
        <h2>
          Install the
          <a href="https://move.gg/3vzakcbr" class="underline">Cavea Demographics</a> Twitch extension to see audience
          data.
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Title, PieChart } from "cavea-design-system";
import countryCodes from "@/lib/helpers/countryCodes";

export default {
  name: "StreamerAudience",

  components: {
    Title,
    PieChart,
  },

  props: {
    handle: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      audienceData: null,
    };
  },

  computed: {
    /**
     * @returns {{ labels: string[], values: number[] }}
     */
    getCountries() {
      if (!this.audienceData?.countries?.length) {
        return this.exampleData;
      }

      const countries = [];

      for (const country of this.audienceData?.countries) {
        countries.push({
          type: countryCodes(country.country, "country"),
          val: country.value,
        });
      }

      countries.sort((a, b) => (a.val < b.val ? 1 : -1));

      return countries;
    },

    /**
     * @returns {{ labels: string[], values: number[] }}
     */
    getLocation() {
      if (!this.audienceData?.location?.length) {
        return this.exampleData;
      }

      const locations = [];

      for (let i = 0; i < this.audienceData?.location?.length; ++i) {
        const city = this.audienceData?.location[i]?.city?.trim();
        const country = this.audienceData?.location[i]?.country?.trim();
        if (city && country) {
          locations.push({
            type: `${city}${`, ${country}`}`,
            val: this.audienceData?.location[i].value,
          });
        }
      }

      locations.sort((a, b) => (a?.val < b?.val ? 1 : -1));

      return locations;
    },

    getLanguages() {
      if (!this.audienceData?.languages) {
        return this.exampleData;
      }

      const languages = [];

      for (const lang of this.audienceData?.languages) {
        lang.name = lang.language.replace(/-.*$/, "").toLowerCase();

        const langIndex = languages.findIndex((elm) => elm.name === lang.name);

        if (langIndex === -1) {
          languages.push(lang);
        } else {
          languages[langIndex].value += lang.value;
        }
      }

      const parsedLang = [];

      for (const lang of languages) {
        parsedLang.push({
          type: lang.name,
          val: lang.value,
        });
      }

      parsedLang.sort((a, b) => (a.value > b.value ? -1 : 1));

      return parsedLang;
    },

    exampleData() {
      return [
        {
          type: "Denmark",
          val: Math.floor(Math.random() * 1000),
        },
        {
          type: "Germany",
          val: Math.floor(Math.random() * 1000),
        },
        {
          type: "Sweden",
          val: Math.floor(Math.random() * 1000),
        },
        {
          type: "Finnish",
          val: Math.floor(Math.random() * 1000),
        },
      ];
    },
  },

  created() {
    this.fetchAudienceData();
  },

  methods: {
    async fetchAudienceData() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator-audience/${this.handle}`;

      const audienceReq = await axios
        .get(URL)
        .then((res) => res.data)
        .catch((err) => {
          console.error("fetchAudienceData error", err);
          return err?.response?.data;
        });

      if (audienceReq?.audience) {
        this.audienceData = audienceReq.audience;
      }
    },
  },
};
</script>
